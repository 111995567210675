.reaction-picker-container {
  width: 100%;
  height: 8rem;

  .reaction-picker {
    display: flex;
    justify-content: center;

    .reaction-btn {
      margin: 0 0.2rem;
      padding: 0;
      background: none;
      transition-duration: 0.05s;

      .content {
        font-size: 3rem;
      }
      &:hover {
        filter: brightness(1.1);
        box-shadow: none;
      }
      &:active {
        transform: scale(1.2, 1.2);
      }
    }
  }
  .count {
    text-align: center;
    margin-top: 0.5rem;
    font-size: 1.6rem;
  }
}
