.finale.game-page {
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8rem 0 0 0;
  margin-top: 8rem;
  @include tablet-below {
    padding: 0 0 2.4rem 0;
  }
  .finale-banner {
    margin-top: -190px;
    width: 100rem;
    @include tablet-below {
      margin-top: -48px;
      width: 37rem;
    }
  }
  .finale-podiums {
    width: 100%;
  }
  .place-bars {
    margin: 72px auto 0 auto;
    width: 20rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
    @include tablet-below {
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 0;
    }
    .stack {
      display: flex;
      flex-direction: column;
      align-items: center;
      @include tablet-below {
        align-items: start;
        width: 100%;
        margin-top: 40px;
      }
    }
    .stack:nth-child(2) {
      @include tablet-below {
        order: -1;
      }
    }
    .def-card {
      background-color: $white;
      border-radius: 8px;
      padding: 8px;
      margin: 0 0 2.4rem 0;
      width: 20rem;
      word-break: break-word;
      @include tablet-below {
        display: none;
      }
      p {
        margin-bottom: 0;
      }
      &.first-def-card {
        border: 4px solid $gold-outline;
      }
      &.second-def-card {
        border: 4px solid $silver-outline;
      }
      &.third-def-card {
        border: 4px solid $bronze-outline;
      }
    }
  }
  .example-podium {
    width: 20rem;
    border-radius: 8px 8px 0 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    @include tablet-below {
      border-radius: 0 8px 8px 0;
      align-items: flex-start;
      background-position: 50% 100%;
    }
    &.first-place {
      margin: 0 8rem 0 8rem;
      height: 20rem;
      background-color: $gold;
      border: 4px solid $gold-outline;
      border-bottom: none;
      background-image: url('../../../assets/1stribbon.png');
      background-position: 50% 10%;
      background-repeat: no-repeat;
      @include tablet-below {
        margin: 0;
        width: 28rem;
        border-left: none;
        border-bottom: 4px solid $gold-outline;
        background-position: 50% 100%;
      }
    }
    &.second-place {
      height: 16rem;
      background-color: $silver;
      border: 4px solid $silver-outline;
      border-bottom: none;
      background-image: url('../../../assets/2ndribbon.png');
      background-position: 50% 10%;
      background-repeat: no-repeat;
      @include tablet-below {
        width: 23rem;
        height: 18rem;
        border-left: none;
        border-bottom: 4px solid $silver-outline;
        background-position: 50% 100%;
      }
      .place-img {
        height: 8rem;
        margin-bottom: -56px;
      }
    }
    &.third-place {
      height: 12rem;
      background-color: $bronze;
      border: 4px solid $bronze-outline;
      border-bottom: none;
      background-image: url('../../../assets/3rdribbon.png');
      background-position: 50% 10%;
      background-repeat: no-repeat;
      @include tablet-below {
        width: 16rem;
        height: 15rem;
        border-left: none;
        border-bottom: 4px solid $bronze-outline;
        background-position: 50% 100%;
      }
      .place-img {
        height: 6rem;
        margin-bottom: -48px;
      }
    }
    .place-img {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 19rem;
      background-image: url('../../../assets/firstplacestar.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 0;
      margin-bottom: -11.2rem;
      padding: 0 9.6rem;
      @include tablet-below {
        margin: 0;
        height: 10.6rem;
      }
      &.second-img {
        background-image: url('../../../assets/secondplacestar.svg');
      }
      &.third-img {
        background-image: url('../../../assets/thirdplacestar.svg');
      }
      p {
        font-family: $display-font;
        font-size: 4rem;
        color: $neon-green;
        -webkit-text-stroke: 2px $dark-cerulean;
        margin: 0;
        text-align: center;
        text-shadow: $boxshadow;
        @include tablet-below {
          font-size: 3.2rem;
          -webkit-text-stroke: 1px $dark-cerulean;
        }
        &.third-name {
          color: $sky-blue;
          font-size: 2.4rem;
          -webkit-text-stroke: 1px $neon-green;
        }
        &.second-name {
          color: $sky-blue;
          font-size: 2.4rem;
          -webkit-text-stroke: 1px $neon-green;
        }
      }
    }
  }
}

.podium {
  margin: 0 auto 96px auto;
  height: 3.5rem;
  width: 100%;
  background-color: $dark-cerulean;
  @include tablet-below {
    display: none;
  }
}

.feedback-container {
  margin-bottom: 2.4rem;
  .feedback {
    font-family: $text-font;
    color: $neon-green;
    margin: 32px 0 0 0;
    text-align: center;
  }
  a {
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }
}
