.expander {
  .expander-header {
    width: 100%;
    margin: 0 auto 1rem;
    font-family: $display-font;
    font-size: 3rem;
    padding: 0;
    color: $burnt-orange;
    background: none;

    &:hover {
      box-shadow: none;
      transform: translateY(-2px);
    }
  }
  .expander-content {
    margin: 0 0 2rem;
    width: 100%;
    overflow: hidden;

    h3 {
      margin-top: 0;
      margin-bottom: 2rem;
    }

    button,
    .visual-line-break {
      display: none;
    }
  }
  .expander-icon {
    margin-left: 0.5rem;
    color: inherit;
    transition-duration: 0.5s;

    svg {
      transition-duration: 0.2s;
    }
    &.expanded svg {
      transform: scaleY(-1);
    }
  }

  section {
    p.instructions {
      font-weight: 400;
      color: $medium-grey;
    }

    h3 {
      margin-bottom: 5px !important;
    }
  }

  @include tablet-below {
    .expander-content {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
    }
  }
}
