*:focus {
  outline: none;
}

*:focus-visible {
  outline: 2px solid $brand-blue;
  outline-offset: -1px;
}

/* Elements */
h1 {
  font-size: 4.8rem;
  margin-bottom: 2.4rem;
  @include tablet-below {
    font-size: 3.2rem;
  }
}

h2 {
  font-size: 3.6rem;
  margin-bottom: 2.4rem;
  @include tablet-below {
    font-size: 2.8rem;
  }
}

h3 {
  font-size: 3.2rem;
  margin-bottom: 0.8rem;
  @include tablet-below {
    font-size: 2.4rem;
  }
}

h1,
h2,
h3,
h4 {
  text-align: center;
  font-family: $display-font;
  color: $brand-blue;
  font-weight: 100;
}

label {
  margin-bottom: 0.8rem;
}

input {
  font-weight: 300;
  font-size: 1.4rem;
  margin-bottom: 2rem;
  padding-left: 8px;
  border: 1px solid $lt-grey;
  border-radius: 0.8rem;
  height: 50px;
}

label,
input,
button,
span {
  color: $medium-grey;
  font-family: $text-font;
  font-size: 1.6rem;
}

label {
  font-weight: 700;
}

input {
  font-weight: 400;
  transition: 0.1s all;
}

input::placeholder {
  color: $lt-grey;
}

p {
  color: $medium-grey;
  font-family: $text-font;
  font-size: 1.8rem;
  margin-bottom: 1.6rem;
  margin-top: 0;
  @include tablet-below {
    font-size: 1.6rem;
  }
  &.word {
    color: $dark-cerulean;
    @include tablet-below {
      font-size: 1.8rem;
    }
  }
}

a {
  font-family: $text-font;
  font-size: 1.8rem;
  transition: 0.3s all;
}

strong {
  color: $nero;
}

textarea {
  font-family: $text-font;
  display: block;
  margin-left: auto;
  margin-right: auto;
  resize: none;
  color: $black;
  font-size: 1.6rem;
  border: 1px solid $silver;
  padding: 15px;
  border-radius: 8px;
  transition: 0.1s all;
  margin-bottom: 2rem;

  &::placeholder {
    color: $lt-grey;
  }
}

button {
  padding: 1.6rem;
  text-decoration: none;
  text-align: center;
  outline: none;
  border: none;
  border-radius: 50px;
  color: $white;
  background-color: $burnt-orange;
  border: $burnt-orange 1px solid;
  font-family: $button-font;
  font-size: 1.6rem;
  width: 100%;
  min-height: 5rem;
  margin: 0 auto 1.6rem;
  max-width: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-transform: uppercase;

  &.expander-header {
    border: none !important;
  }

  &.secondary {
    color: $burnt-orange;
    background-color: $white;
    font-weight: 700;
    box-sizing: border-box;
    &:disabled {
      background-color: $lt-grey;
      color: $white;
      border: none;
    }

    &:hover {
      background: $brand-blue;
      color: $white;
      border-color: $brand-blue;
    }
  }
  &:hover {
    background: $brand-blue;
    color: $white;
    border-color: $brand-blue;
  }
  &:disabled,
  &.disabled {
    background-color: $lt-grey;
    border-color: $lt-grey !important;
    cursor: not-allowed;

    &:hover {
      color: $white !important;
    }
  }
  &.selectable {
    color: $medium-grey;
    background-color: $white;
    border: 2px solid $lt-grey;
  }
  &.selected {
    //box-shadow: $boxshadow;
    background-color: $neon-green;
    color: $dark-cerulean;
  }
  &.selected.selectable {
    border: 2px solid $dark-cerulean;
  }
  &.sm-btn {
    background: none;
    border: 1px solid $burnt-orange;
    color: $burnt-orange;
    font-weight: bold;
    font-size: 1.2rem;
    min-height: 2.4rem;
    padding: 0 8px;
  }
  &.choose-word.sm-btn {
    display: block;
    margin: 0 0 1.6rem 0.8rem;
    padding: 8px 16px;
  }
}

a {
  color: $sky-blue;
}

input[type='checkbox'] {
  appearance: none;
  -webkit-appearance: none;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 8px;
  border-radius: 0.2rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

input[type='checkbox']:checked {
  background-color: $neon-green;
  border: 2px solid $dark-cerulean;
}

input[type='checkbox']:checked:after {
  font-family: $display-font;
  color: $dark-cerulean;
  font-size: 4rem;
  content: '✓';
  margin-bottom: 2rem;
  margin-left: 1rem;
}

/* Modifiers */

.checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  input {
    margin: 0 10px 0 0;
  }

  label {
    cursor: pointer;
    margin-bottom: 0;
  }

  .message {
    background: $neon-green;
    color: $black;
    font-size: 1.3rem;
    padding: 5px;
    margin-left: 10px;
  }
}

.radio-input-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  input {
    margin: 0 10px 0 0;
  }

  label {
    cursor: pointer;
    margin-bottom: 0;
  }
}

.auto-width {
  width: auto;
}

.bot-margin {
  margin-bottom: 4rem;
}

.align-self {
  align-self: center;
}

.center-text {
  text-align: center;
}

.align-right {
  margin-right: 0;
}

.display-block {
  display: block;
}

.LR-automargin {
  margin-left: auto;
  margin-right: auto;
}

.orange {
  background: $burnt-orange;
}

.word-label {
  font-size: 2.8rem;
}

.add-top-margin {
  margin-top: 2.4rem;
}

.no-bottom-margin {
  margin-bottom: 0;
}

//hide labels, from Manuel Matuzović:
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
}

/* Classes/Ids */

section.text-content,
div.text-content {
  margin-bottom: 4rem;

  h3 {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }
  p {
    margin-bottom: 4rem;
  }
}

div.visual-line-break {
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid $nero;
}

.or {
  text-align: center;
  font-size: 2rem;
  vertical-align: middle;
  margin: 10px auto;
  text-transform: lowercase;
  font-weight: 400;
}

//error
.error {
  text-align: center;
  color: $black;
  background: $neon-green;
  font-family: $text-font;
  padding: 15px;
  margin-bottom: 3rem;
  border-radius: 8px;
  font-weight: 700;
}

.outside-error {
  margin: 0 auto 1.6rem auto;
  width: auto;
  text-align: center;
  background-color: $white;
  border-radius: 16px;
  padding: 8px 16px 8px 16px;
  font-size: 1.8rem;
  color: $burnt-orange;
  font-family: $display-font;
}

.host-dropdown {
  width: 16rem;
  height: 5rem;
  border-radius: 8px;
  margin-bottom: 1.6rem;
  padding-left: 0.8rem;
}

#definition {
  width: 100%;
}

.player-submitted {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid black;
  border-radius: 8px;
  margin-bottom: 16px;
  word-wrap: break-word;

  h3 {
    margin-top: 0;
  }
}

.player-guess {
  text-align: center;
}

.player-display {
  margin: 2.5rem auto;
  .player-h2 {
    color: $brand-blue;
    text-align: left;
    font-size: 3.2rem;
    margin-bottom: 0.8rem;

    @include tablet-below {
      text-align: center;
    }
  }
  @include tablet-below {
    margin-top: 0.8rem;
  }
}

.error-div {
  margin: 8px auto;
}

.player-lobby {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 16px;

  &.add-bottom-margin {
    margin-bottom: 32px;
  }
  .players {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background: $white;
    border: 1px solid $lt-grey;
    border-radius: 8px;
    padding: 8px 16px;
    margin: 0 8px 16px 0;

    &.current {
      .player {
        color: $burnt-orange;
      }
    }

    &.disconnected {
      background: $lt-grey;
    }

    @include tablet-below {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding: 8px 16px;
      margin: 0 0 8px 0;
    }
    &.submitted {
      color: $dark-cerulean;
      border: 2px solid $dark-cerulean;
      background: $neon-green;
      p {
        color: $dark-cerulean;
      }
    }
    .player {
      font-family: $text-font;
      font-size: 1.6rem;
      margin-bottom: 0;
      padding: 0.8rem;
      font-weight: 700;
      @include tablet-below {
        font-size: 1.4rem;
      }
    }
    p.player-score {
      display: none;
      margin: 0 auto;
      font-size: 1.4rem;
      @include tablet-below {
        font-size: 1.2rem;
        margin: 0;
      }
    }
  }
}
