.char-counter-wrapper {
  position: relative;

  .char-counter {
    position: absolute;
    right: 1rem;
    bottom: -1rem;
  }
  &.lower {
    .char-counter {
      bottom: -3rem;
    }
  }
  &.higher {
    .char-counter {
      bottom: 1rem;
    }
  }
}

.char-counter {
  p {
    color: $lt-grey;
  }
  p.full {
    color: $burnt-orange;
  }
}

.max-width-35-center {
  max-width: 35rem;
  margin: 0 auto;
}
