.pregame {
  .page-title {
    padding: 3rem 8.5rem 2rem;
  }

  h3 {
    margin-top: 0;
    text-align: left;
  }

  p {
    color: $black;
  }

  section {
    padding: 3rem 8.5rem;

    &.white-bg {
      background: $white;
    }

    .description {
      margin-bottom: 2.5rem;
    }
  }

  .invite-code {
    display: flex;
    align-self: center;
    border-radius: 16px;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;

    .lobby-code {
      font-weight: 400;
      font-family: $display-font;
      color: $medium-grey;
      font-size: 4.5rem;
      background-color: $white;
      padding: 15px;
      border-radius: 8px;
      width: 140px;

      span {
        display: block;
        font-family: $text-font;
        font-weight: 700;
      }
    }

    .separator {
      padding: 0 3rem;
      font-weight: 700;
      color: $medium-grey;
    }

    @include large-below {
      max-width: 16rem;
    }
  }

  .select-bot {
    padding-left: 15px;
  }

  .word-category {
    p {
      font-weight: 700;
      color: $medium-grey;
      font-size: 1.6rem;
      margin-bottom: 0;
    }

    .categories {
      display: flex;
      justify-content: space-between;
    }
  }

  .expander {
    .expander-header {
      text-align: left;
    }

    .expander-content {
      margin-top: 3rem;
    }

    section {
      padding: 0 !important;

      button,
      .visual-line-break {
        display: none;
      }

      p.instructions {
        text-align: left;
      }
    }
  }
}

.pick-word-instructions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;

  p.instructions {
    margin-bottom: 0;
    width: 65%;
    text-align: left !important;

    @include tablet-below {
      width: 100%;
    }
  }
  button {
    margin: 0;
    padding: 8px 16px !important;
  }
  @include tablet-below {
    justify-content: center;
    margin: 0 0 1.6rem 0;
    width: 100%;
  }
}

.word-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 16px;
  // padding: 24px;
  margin: 3rem auto;

  .word-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  #word {
    width: 100%;
  }
  .word-definition {
    width: 100%;
    background: rgba(213, 255, 46, 0.15);
    border-radius: 8px;
    padding: 15px;
  }
  .sm-word {
    color: $black;
    font-size: 1.4rem;
    font-weight: 700;
    margin-bottom: 0.8rem;
    text-transform: uppercase;
    font-family: $button-font;
  }
  .word {
    font-family: $display-font;
    font-size: 2.4rem;
    margin: 0.8rem 0 1.6rem 0;
    word-break: break-word;
    color: $black;
  }
}

.use-own-words {
  margin-top: 1.6rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @include tablet-below {
    flex-direction: column;
  }
}

.word-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.word-choice {
  display: block;
  min-width: 20rem;
  width: 30%;
  margin-bottom: 0.8rem;
  padding-bottom: 1rem;
  color: $black;
  font-size: 2.4rem;
  font-family: $text-font;
  border-radius: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;

  &:hover {
    border-color: $dark-cerulean;
    color: $dark-cerulean;
  }

  &.writing {
    border-style: dashed;
    border-radius: 8px;
  }

  @include tablet-below {
    font-size: 1.6rem;

    &.single-word {
      text-align: center;
      font-size: 4.8rem;
      color: $sky-blue;
    }
  }

  .definition {
    font-weight: 300;
    border: 1px dashed $dark-blue;
  }
}

.timer-container {
  .quantity-wrapper {
    border: 1px solid $lt-grey;
    border-radius: 6px;
    display: flex;
    width: 200px;
    font-size: 2rem;
    overflow: hidden;
    margin-bottom: 2rem;

    button {
      background: none;
      margin: 0;
      color: $lt-grey;
      border: none;
      border-radius: 0;

      font-size: 3rem;
      color: $medium-grey;
      padding: 0;

      &:disabled {
        background: $lt-grey !important;
        cursor: not-allowed;
        color: $black !important;
      }

      &:hover {
        background: $brand-blue;
        color: white;
      }
    }

    .timer-itself {
      align-items: center;
      margin: 0 auto;
      width: 400px;
      font-size: 2rem;
      color: $medium-grey;
      text-align: center;
      padding-left: 0;
      padding-right: 0;
      border-radius: 0;
      border-left: 1px solid $lt-grey;
      border-right: 1px solid $lt-grey;
      border-top: none;
      border-bottom: none;

      &:disabled {
        background: $lt-grey;
        cursor: not-allowed;
      }
    }

    input[type='number']::-webkit-inner-spin-button {
      opacity: 1;
      padding: 0;
      right: 8px;
      height: 4rem;
      cursor: pointer;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .timer-wrap {
    display: flex;
    align-items: center;

    input,
    label {
      margin: 0;
      padding: 0;
    }

    input {
      margin-right: 15px;
    }

    label {
      font-weight: 400;
    }
  }
}

.edit-name-block {
  display: flex;
  justify-content: flex-end;
  .edit-name {
    width: 150px;
    height: 50px;
    background-color: $sky-blue;
  }
}

.edit-name-form {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 1.6rem;
  max-width: 100%;

  button {
    width: 100% !important;
    margin: 0;
  }
}

.short.error {
  position: relative;
}

/* Responsive */

@media screen and (max-width: $mid) {
  .invite-code {
    flex-direction: column;
  }
}

@media screen and (max-width: $tablet) {
  .pregame {
    .page-title {
      padding: 0 !important;
    }

    section {
      padding: 2rem 0 !important;
    }

    .invite-code {
      max-width: 100% !important;
    }

    .pick-word-instructions {
      display: block;
      text-align: center;

      p {
        text-align: left;
        margin-bottom: 15px;
      }
    }

    .word-list {
      display: block;

      button {
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
