.pro-tip-btn {
  display: none;
  position: absolute;
  top: -50px;
  left: -50px;
  z-index: 100;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 !important;
}
