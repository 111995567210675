// Add this drop shadow to different containers to give your page a more 3D-feel!
@mixin drop-shadow($color: #0005) {
  box-shadow: 0 4px 4px $color;
  -moz-box-shadow: 0 4px 4px $color;
}

@mixin mid-below {
  @media (max-width: #{$mid}) {
    @content;
  }
}
@mixin tablet-below {
  @media (max-width: #{$tablet}) {
    @content;
  }
}
@mixin large-below {
  @media (max-width: #{$large}) {
    @content;
  }
}
@mixin x-large-below {
  @media (max-width: #{$x-large}) {
    @content;
  }
}
@mixin x2-large-below {
  @media (max-width: #{$x2-large}) {
    @content;
  }
}
