.player-scoreboard {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 200;
  display: none;
  align-items: center;
  justify-content: center;
  opacity: 0;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 200;
  }

  .inner-scoreboard {
    background-color: #0053a1;
    z-index: 210;
    border-radius: 5px;
    max-width: 100%;
    transform: translate(0, 100px);

    .scoreboard-heading {
      position: relative;

      h2 {
        color: $white;
        font-size: 3rem;
        padding: 5rem 2rem 2rem;
        margin-bottom: 0;
      }

      button {
        width: 100px;
        height: 40px;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        min-height: 0 !important;
      }
    }

    .scoreboard-table {
      box-sizing: border-box;
      width: 100%;
      overflow-x: scroll;

      .row {
        display: flex;
        background-color: #002654;
        align-items: center;

        td {
          padding: 1rem;
        }

        &.no-players {
          font-family: $text-font;
          text-align: center;
          padding: 2rem;
        }

        &.disconnected {
          background-color: $medium-grey;
        }

        &.heading {
          background-color: #003b7a;

          .round {
            text-align: center;
          }

          td {
            color: $white;
            font-family: $display-font;
          }
        }

        .username {
          width: 11rem;
          text-align: center;
        }

        .round {
          width: 5rem;
          text-align: center;
        }

        .total {
          width: 7rem;
          text-align: center;
        }

        .placing {
          width: 5rem;
          text-align: center;
        }

        &.player {
          border-bottom: 1px solid #b7b7b8;

          td {
            color: $white;
            font-weight: 300;
            font-size: 1.4rem;
            font-family: $text-font;
          }

          &:last-child {
            border-bottom: none !important;
          }
        }

        &.current {
          background-color: #28a9fa;

          td {
            font-weight: 800;
          }
        }
      }
    }
  }
}

.show-scoreboard {
  position: absolute;
  top: -50px;
  right: -50px;
  z-index: 100;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 !important;

  @include large-below {
    top: auto !important;
    bottom: 0;
    left: 0;
    right: auto !important;
    position: fixed !important;
    width: 100%;
    border-radius: 0 !important;
    height: 70px !important;
  }
}

.App {
  @include large-below {
    padding-bottom: 70px;
  }
}
