.guessing {
  .page-title {
    padding: 3rem 8.5rem 2rem;
  }

  section {
    padding: 3rem 8.5rem;

    &.white-bg {
      background: $white;
    }
  }

  p.instructions {
    margin: 0 auto;
    text-align: center;
    width: 65rem;
    max-width: 100%;
    font-weight: 700;
    line-height: 1.5em;
    font-size: 2rem;
  }

  .word-label,
  .guess-label {
    width: 18rem;
    text-align: left;
  }

  .guess-word {
    .word {
      margin-left: 0;
      max-width: 70%;
    }
  }

  .player-guess {
    display: flex;
    margin-bottom: 3rem;

    .guess-label {
      font-size: 2.8rem;
    }

    .guess {
      border: 2px solid $dark-cerulean;
      border-radius: 8px;
      padding: 1.5rem 4rem 0;
      max-width: 70%;

      p {
        font-size: 2.5rem;
        color: $black;
        text-align: left;
        font-weight: 700;
      }
    }
  }

  .definition {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid $lt-grey;
    border-radius: 8px;
    margin-bottom: 15px;
    padding: 16px 8px 16px 8px;

    &:last-of-type {
      margin-bottom: 2.4rem;
    }
    p {
      margin-bottom: 0;
      @include tablet-below {
        text-align: left;
      }
      &:last-of-type {
        word-break: break-word;
      }
    }
    .definition-content {
      align-self: center;
      text-align: left;
      margin: 0;
    }
    .definition-key {
      display: flex;
      justify-content: center;
      margin: 0 2rem 0 0;
      color: $nero;
      background: $white;
      border-radius: 8px;
      height: 4rem;
      p {
        width: 6.5rem;
        font-size: 4rem;
        font-family: $display-font;
        color: $sky-blue;
      }
    }
  }
}

.definition-guess {
  text-align: left;
}

.submit-guesses {
  display: flex;
  justify-content: center;
  margin-top: 1.6rem;
}

.show-guess {
  p.guess-choice {
    word-wrap: break-word;
  }
}

.guesses {
  h2 {
    margin-top: 4.8rem;
  }

  .row {
    display: flex;
    width: 100%;
    border-bottom: $medium-grey 1px solid;
    padding: 10px 0;
    align-items: center;
    flex-wrap: wrap;

    .guess-name {
      width: 20%;
    }

    .guess-vote {
      width: 55%;
    }
  }

  .voting-heading {
    div {
      font-size: 2rem;
      margin: 0;
      font-family: $display-font;
      color: $brand-blue;
      font-weight: 100;
    }
  }

  .guess {
    .guess-name {
      font-size: 1.8rem;
      color: $medium-grey;
      font-family: $text-font;
      font-weight: 700;
      position: relative;

      .open-guess {
        position: absolute;
        top: -10px;
        left: -40px;
      }

      .modal-content {
        h2 {
          text-align: center;
          margin: 0;
          width: 100%;
        }

        p {
          text-align: center;
        }

        button {
          width: auto !important;
          height: auto !important;
          padding: 5px 20px;
        }
      }
    }

    button {
      min-height: unset;
      height: 3.2rem;
      width: 3.2rem;
      padding: 0;
      margin: 4px;
    }

    .word-block {
      width: 100%;
      margin: 1rem 0;

      .definition {
        margin: 0;
        display: block;
        text-align: left;
        border: none;
        padding: 0 0 0 5px;
        color: $black;
      }
    }
  }
}

.notes {
  text-align: left;
  textarea {
    width: 100%;
    height: 10rem;
    max-width: 100%;
    border-radius: 8px;
    border: 2px solid $nero;
  }
}

/* Responsive */

@media screen and (max-width: $mid) {
  .guessing {
    .definition {
      display: block;
      text-align: center;

      .definition-key {
        margin-bottom: 15px;
      }

      p {
        text-align: center;
      }
    }

    .guesses {
      .voting-heading {
        display: none !important;
      }

      .row {
        display: block;
        text-align: center;

        .guess-name {
          width: 100%;
          margin-bottom: 5px;
        }

        .guess-vote {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  .guessing {
    .page-title {
      padding: 0 !important;
    }

    section {
      padding: 2rem 0 !important;
    }

    .player-guess {
      display: block;
      width: 100%;

      .guess-label {
        width: 100%;
        padding: 0;
        margin-bottom: 10px;
        text-align: center;
      }

      .guess {
        width: 100%;
        max-width: 100%;
        padding: 20px;

        p {
          font-size: 2.2rem;
        }
      }
    }
  }
}
