@import '../../globals.scss';
@import '../../mixins.scss';

.landing-page {
  .landing-content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    & > div {
      width: 800px;
      max-width: 100%;
    }
  }
}
