// Set box-sizing globally
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

// Ensure the app containers fills the screen
html,
body,
#root {
  min-height: 100vh;
  padding: 0;
  margin: 0;
}

// Set the font-size so 1rem = 10px for easier styling
html {
  font-size: 62.5%;
}

input,
label {
  display: block;
}

strong {
  font-weight: 600;
}

h1,
h2,
h3,
p {
  margin-top: 0;
}

// Font Variables
$text-font: 'Roboto', sans-serif;
$display-font: 'Luckiest Guy', sans-serif;
$button-font: 'Mulish', sans-serif;

// Media Query Sizes
$mid: 500px;
$tablet: 650px;
$large: 768px;
$x-large: 1290px;
$x2-large: 1600px;

//box-shadow
$boxshadow: 4px 4px 4px rgba(0, 0, 0, 0.25);

//box-sizing
$box-sizing: border-box;

// NEW COLORS
$medium-grey: #5a5b5b;
$dark-blue: #40537f;
$white: #ffffff;
$lighter-grey: #f9f9f9;
$charcoal: #343434;
$lime: #bfd349;
$lt-grey: #c4c4c4;
$black: #000000;
$nero: #131313f1;
$black-2: #121212;
$neon-green: #d5ff2e;
$brand-blue: #0089ed;
$sky-blue: #1da1f2;
$sky-blue-light: #e8f6fe;
$dark-cerulean: #064165;
$burnt-orange: #ff7f34;

//finale colors
$gold: #ffd645;
$gold-outline: #ffb800;
$silver: #c4c4c4;
$silver-outline: #8c8b8b;
$bronze: #e08c29;
$bronze-outline: #9f4c00;

#root {
  font-size: 16px;
  background-color: $black-2;
}

// Put footer at the bottom
.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App > div:not(.modal-wrapper):not(.cookie-popup) {
  display: flex;
  flex-flow: column nowrap;
}
