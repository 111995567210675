.cookie-popup {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  background-color: #fffd;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  .popup-content {
    width: 400px;
    max-width: 100%;
    h3 {
      color: $sky-blue;
      text-shadow: 0 2px 2px #0005;
      font-size: 3rem;
      line-height: 3.4rem;
      font-family: $display-font;
      margin-bottom: 0.5rem;
    }
    p {
      font-family: $text-font;
      font-size: 1.4rem;
      line-height: 1.6rem;
      font-weight: 300;
      letter-spacing: 0.03rem;
      color: $charcoal;
      margin-bottom: 1rem;
    }
    .popup-bottom {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      label {
        color: $charcoal;
        font-family: $text-font;
        font-size: 1.2rem;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin-bottom: 1.6rem;
        input {
          margin-right: 0.8rem;
          margin-bottom: 0.4rem;
        }
      }
      button {
        height: 50px;
        width: 150px;
      }
    }
  }
}
