.modal {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  .modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40rem;
    min-height: 20rem;
    max-width: 100%;
    padding: 1.6rem;
    background-color: $white;
    border-radius: 1.6rem;
    box-shadow: $boxshadow;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 2.4rem;
      align-self: start;
    }
    button {
      width: auto;
      &:first-child {
        margin-right: 1.6rem;
      }
    }
    p {
      align-self: start;
    }
  }
}
