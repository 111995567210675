.copy-to-clipboard {
  bottom: 2.8rem;
  right: 0rem;
  cursor: pointer;

  p {
    position: absolute;
    width: 9rem;
    font-size: 1.2rem;
    bottom: -3.3rem;
    margin: 0;
    background: $sky-blue;
    color: $white;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
  }
  button {
    background: none;
    margin: 0;
    color: $burnt-orange;
    font-size: 2.4rem;
    animation-duration: 1s;
    border-radius: 8px;
    padding: 15px;

    span {
      transition: 0.3s all;
      display: block;
      font-size: 1.2rem;
      font-family: $text-font;
      color: $burnt-orange;
      font-weight: 700;
      text-transform: none;
      margin-bottom: 10px;
    }

    svg {
      font-size: 5rem;
    }

    &:hover {
      box-shadow: none;
      background: $burnt-orange;
      color: $white !important;

      span {
        color: $white;
      }
    }
    &.copied {
      animation-name: copy-to-clipboard;
    }
  }
  @include large-below {
    right: -2rem;
  }
}
