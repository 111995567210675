// Header - the bar at the top of the screen
header {
  width: 300px;
  height: 16rem;
  max-width: 100%;
  padding: 0.8rem 0;
  color: $white;
  text-align: center;

  h2,
  p {
    transition: 0.3s all;
  }

  a {
    text-decoration: none;

    &:hover {
      h2,
      p {
        opacity: 0.7;
      }
    }
  }

  h2 {
    color: $neon-green;
    margin-bottom: 5px;
    font-size: 4.8rem;
  }

  .trick-logo {
    display: block;
    width: 22rem;
  }

  p {
    color: $white;
    margin-bottom: 0;
    font-family: $text-font;
    font-size: 1.8rem;
    font-weight: 400;
  }
  .welcome-word {
    margin-left: 3.2rem;
    margin-top: 0;
    margin-bottom: 4rem;
  }

  @include x-large-below {
    width: 100%;
    text-align: center;
    height: auto;
    margin-bottom: 30px;
  }
}
